import React, { Component } from 'react';
import Scroll from './Scroll';
import Link from 'gatsby-link';
import logo from '../assets/images/familiesforhomes.svg';


export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
      visibilityClass: '',
    };
  }
  toggleMenu = value => {
    this.setState({ openMenu: value });
  };

  testButton = () =>{
    console.log("Make sure this is working")
  }

  handleScroll = () => {
    const { visibilityClass } = this.state;
    console.log("here")
    if (window.pageYOffset > 50) {
      console.log("test");
      if (visibilityClass !== 'navbar-shrink') {
        this.setState({ visibilityClass: 'navbar-shrink' });
      }
    } else {
      if (visibilityClass === 'navbar-shrink') {
        console.log("yo")
        this.setState({ visibilityClass: '' });
      }
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    const { openMenu, visibilityClass } = this.state;
    return (
      <nav
        className={`navbar navbar-expand-lg navbar-light fixed-top ${visibilityClass}`}
        

        id="mainNav"
      >
        <div className="container">
          <a className="navbar-brand">
            <Link to="/" className="navbar-item" title="Logo">
              <img src={logo} alt="Two Beta" style={{ width: '60px' }} />
            </Link>
          </a>

     

          <button
            onClick={_ => this.toggleMenu(!openMenu)}
            className={`navbar-toggler navbar-toggler-right ${
              openMenu ? '' : 'collapsed'
              }`}
            type="button"
            aria-controls="navbarResponsive"
            aria-expanded={openMenu}
            aria-label="Toggle navigation"
          >

            <i className="fas fa-bars"></i>
          </button>

          <div
            className={`collapse navbar-collapse ${openMenu ? 'show' : ''}`}
            id="navbarResponsive"
          >
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Scroll
                  // testing where the console.log will show up
                  onClick={this.testButton}
                  // onClick={_ => this.toggleMenu(!openMenu)}
                  type="id"
                  element="home"
                >
                  <a className="nav-link" >
                    <Link to="/" className="navbar-item" title="Home">
                      Home
              </Link>
                  </a>
                </Scroll>
              </li>

              <li className="nav-item">
                <Scroll
                  onClick={_ => this.toggleMenu(!openMenu)}
                  type="id"
                  element="families"
                >
                  <a className="nav-link" >
                    <Link to="/families" className="navbar-item" title="Family Profile">
                      Family Profile
                </Link>
                  </a>
                </Scroll>
              </li>

              <li className="nav-item">
                <Scroll
                  onClick={_ => this.toggleMenu(!openMenu)}
                  type="id"
                  element="about"
                >
                  <a className="nav-link" >
                    <Link to="/about" className="navbar-item" title="About Us">
                      About Us
                </Link>
                  </a>
                </Scroll>
              </li>

              <li className="nav-item">
                <div className="nav-link">
                  <a className="navbar-item" href={"https://two-beta-donations.herokuapp.com/"}>
                    Donate
                    </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
